const contentData = [
  {
    title: "Pipelinerapport",
    link: "https://auth0.com/docs/connections",
    description:
      "Pipeline viser en oversigt over igangværende salg."
  },
  {
    title: "Salgsrapport",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Her ses en samlet salgsrapport, der samler budgettal og omsætningstal i én oversigt."
  },
  {
    title: "Sagsoversigt",
    link: "https://auth0.com/docs/anomaly-detection",
    description:
      "Her ses en oversigt over alle igangværende sager (Projekter) der er i gang hos Easy Fair"
  },
  {
    title: "Flere funktioner?",
    link: "https://doweb.dk",
    description:
      "Dette website er leveret af DoWEB ApS. For flere funktioner og / eller tilpasninger, smid os endelig en mail eller et telefonopkald, og så vender vi tilbage asap."
  }
];

export default contentData;
