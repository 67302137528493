// React Hooks
import React, { useEffect, useState } from "react";

// Authentication
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";

// Utilities
import { callApi } from "../utils/Api";
import { defaultStaticRanges, formattedDate } from "../utils/DateRangeConfig";

// Components
import Loading from "../components/Loading";
import ApiAlerts from "../components/alerts/ApiAlerts";
import { TableComp } from '../components/table/static/Table';
import { DateRangePicker } from "react-date-range";
import { startOfMonth, endOfMonth } from "date-fns";
import { da } from "date-fns/locale";

// External Components
import { MDBSelect, MDBRow, MDBCol, MDBSpinner, MDBIcon, MDBCollapse } from 'mdb-react-ui-kit';

export const Pipelineoversigt = () => {
  const {
    getAccessTokenSilently
  } = useAuth0();

  const [ProbList, setProbList] = useState({ list: [], showFilter: false, error: '' });
  const [associatesList, setAssociatesList] = useState({ list: [], showFilter: false, error: '' });
  const [showFilterCollapse, setFilterCollapse] = useState(false);

  const toggleFilterCollapse = () => setFilterCollapse(!showFilterCollapse);

  const [dataState, setDataState] = useState({
    showData: false,
    apiRaw: "",
    error: null,
    tableHeader: [],
    tableBody: [],
    tableSubHeader: [],
    tableFooter: [],
    initDone: false
  });

  const [filterState, setFilterState] = useState({
    route: 'pipeline'
  });

  const setRoute = (routeName, params = {}) => {
    setDataState({
      ...dataState,
      showData: false,
      apiRaw: ""
    });

    setFilterState({
      ...filterState,
      params: params,
      route: routeName,
    });
  };

  const getFilterList = async (entity, setFilterDataState) => {
    const response = await callApi(getAccessTokenSilently, entity, filterState.params);

    if (response.status) {
      setFilterDataState({ list: response.data, showFilter: true, error: '' });
    } else {
      setFilterDataState({ list: [], showFilter: false, error: response.error });
    }
  }

  const getData = async () => {
    const response = await callApi(getAccessTokenSilently, filterState.route, filterState.params);

    if (response.status) {
      const tableheader = [];
      const tablebody = [];

      if (response.data.length > 0) {
        for (var col in response.data[0]) {
          if (response.data[0].hasOwnProperty(col)) {
            tableheader.push({ label: col, colspan: 1, className: typeof response.data[0][col] === "number" ? "text-end" : "text-start" });
          }
        }

        response.data.forEach(row => {
          tablebody.push(Object.values(row).map(col => (
            {
              value: col,
              className: typeof col === "number" ? "text-end" : ""
            }

          )));
        });
      }

      setDataState({
        ...dataState,
        showData: true,
        apiRaw: response.data,
        tableHeader: tableheader,
        tableBody: tablebody,
        tableSubHeader: "",
        tableFooter: [],
        filterData: {},
        error: null,
      });
    }
    else {
      setDataState({
        ...dataState,
        apiRaw: null,
        error: response.error
      });
    }
  };

  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  if (!dataState.initDone) {
    getFilterList('associates', setAssociatesList);
    getFilterList('prob', setProbList);
    setDataState({ ...dataState, initDone: true });
  }

  useEffect(() => {
    if (dataState.initDone) {
      getData();
    }
  }, [filterState, dataState.initDone]);

  return (
    <>
      <ApiAlerts dataState={dataState} setDataState={setDataState} />
      <MDBRow className="mt-5 pt-4 mb-2">
        <h2>Pipelineoversigt</h2>
      </MDBRow>
      <div className="card mb-5 mt-5">
        <div className="card-body" style={{ textAlign: "center" }}>
          <h5
            className="card-title mb-0 text-center"
            onClick={toggleFilterCollapse}
            style={{ cursor: "pointer", display: "inline-block" }}
          >
            <MDBIcon fas icon="filter" /> Indstil filtre
          </h5>
          <MDBCollapse show={showFilterCollapse}>
            <MDBRow className="mb-5 mt-4">
              <MDBCol className="col-12 col-lg-6">
                {associatesList.showFilter && (
                  <MDBSelect
                    data={associatesList.list}
                    multiple
                    label='Medarbejdere'
                    selectAllLabel='Vælg alle'
                    clearBtn
                    search
                    searchLabel='Søg...'
                    onValueChange={(e) => setRoute(filterState.route, { ...filterState.params, associate_id: e.map(item => item.value) })}
                  />
                )}
                {!associatesList.showFilter && (
                  <MDBSelect
                    data={[]}
                    label='Medarbejdere'
                    disabled
                  />
                )}

                {/*<DropDownComp name="Status" _data={ProbList} _onChange={(e, item) => setRoute(e, filterState.route, { ...filterState.params, projstatus_id: item.value })} />*/}

                {ProbList.showFilter && (
                  <MDBSelect
                    className="mt-4"
                    data={ProbList.list}
                    multiple
                    label='Status'
                    selectAllLabel='Vælg alle'
                    clearBtn
                    search
                    searchLabel='Søg...'
                    onValueChange={(e) => setRoute(filterState.route, { ...filterState.params, probability_idx: e.map(item => item.value) })}
                  />
                )}
                {!ProbList.showFilter && (
                  <MDBSelect
                    className="mt-4"
                    data={[]}
                    label='Status'
                    disabled
                  />
                )}
              </MDBCol>
              <MDBCol className="col-md-12 col-lg-6">
                <DateRangePicker
                  onChange={(item) => {
                    setRoute(filterState.route, {
                      ...filterState.params,
                      startDate: formattedDate(item.selection.startDate),
                      endDate: formattedDate(item.selection.endDate),
                    });
                    setDatePickerState([item.selection]);
                    console.log(item);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  locale={da}
                  ranges={datePickerState}
                  direction="vertical"
                  staticRanges={defaultStaticRanges}
                  inputRanges={[]}
                  weekStartsOn={1}
                />
              </MDBCol>
            </MDBRow>
          </MDBCollapse>
        </div>
      </div>

      <MDBRow className="mb-5">
        {/* Display Table */}
        {dataState.showData && dataState.tableBody.length > 0 && (
          <MDBCol className="col-12" >
            <TableComp _tableBody={dataState.tableBody} _tableHeader={dataState.tableHeader} _tableSubHeader={dataState.tableSubHeader} _tableFooter={dataState.tableFooter} />
          </MDBCol>
        )}
        {/* No Data */}
        {dataState.showData && dataState.tableBody.length === 0 && (
          <MDBCol className="col-12" >
            <p className="text-center">Datsættet returnerede ingen data...</p>
          </MDBCol>
        )}
        {/* Loading */}
        {!dataState.showData && (
          <MDBCol className="col-12">
            <div className='d-flex justify-content-center'>
              <MDBSpinner size='lg' role='status'>
                <span className='visually-hidden'>Indlæser...</span>
              </MDBSpinner>
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </>
  );
};

export default withAuthenticationRequired(Pipelineoversigt, {
  onRedirecting: () => <Loading />,
}); 