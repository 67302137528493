// React Hooks
import React, { useEffect, useState } from "react";

import {
  MDBSelect,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBChart,
  MDBCollapse,
  MDBIcon
} from "mdb-react-ui-kit";

// Authentication
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";

// Utilities
import { callApi } from "../utils/Api";

// Components
import Loading from "../components/Loading";
import ApiAlerts from "../components/alerts/ApiAlerts";
import { TableComp } from "../components/table/static/Table";
import { startOfQuarter, endOfQuarter} from "date-fns";

import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges, formattedDate } from "../utils/DateRangeConfig";
import { da } from "date-fns/locale";
export const Budgetoversigt = () => {
  //array of month names in danish
  const monthNames = [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const { getAccessTokenSilently } = useAuth0();

  const [showFilterCollapse, setFilterCollapse] = useState(false);

  const toggleFilterCollapse = () => setFilterCollapse(!showFilterCollapse);

  const [associatesList, setAssociatesList] = useState({
    list: [],
    showFilter: false,
    error: "",
  });

  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: startOfQuarter(new Date()),
      endDate: endOfQuarter(new Date()),
      key: "selection",
    },
  ]);

  const [dataState, setDataState] = useState({
    showData: false,
    apiRaw: "",
    error: null,
    tableHeader: [],
    tableBody: [],
    tableSubHeader: [],
    tableFooter: [],
    rowTotal: [],
    colTotal: [],
  });

  const [filterState, setFilterState] = useState({
    route: "budget",
    startDate: formattedDate(startOfQuarter(new Date())),
    endDate: formattedDate(endOfQuarter(new Date())),
  });

  const setRoute = (routeName, params = {}) => {
    setDataState({
      ...dataState,
      showData: false,
      apiRaw: "",
      rowTotal: [],
      colTotal: [],
    });
    console.log("setRoute", routeName, params);
    setFilterState({
      ...filterState,
      params: params,
      route: routeName,
    });
  };

  const getFilterList = async (entity, setFilterDataState, filterDataState) => {
    if (!filterDataState.showFilter) {
      const response = await callApi(
        getAccessTokenSilently,
        entity,
        filterState.params
      );

      if (response.status) {
        setFilterDataState({
          list: response.data,
          showFilter: true,
          error: "",
        });
      } else {
        setFilterDataState({
          list: [],
          showFilter: false,
          error: response.error,
        });
      }
    }
  };

  const getData = async () => {
    const response = await callApi(
      getAccessTokenSilently,
      filterState.route,
      filterState.params
    );

    if (response.status) {
      //Calculate budget
      var o = response.data.reduce((a, b) => {
        a[b.FullName] = a[b.FullName] || [];
        var monthData = {
          [b.StartDate]: {
            associateId: b.AssociateId,
            turnover: b.turnover,
            budget: b.budget,
            deviation: b.turnover - b.budget,
          },
        };
        a[b.FullName].push(monthData);

        return a;
      }, {});

      var a = Object.keys(o).map(function (k) {
        return { associate: k, data: Object.assign.apply({}, o[k]) };
      });

      let tableheader = [{ label: "", colspan: 1 }];
      let tablesubheader = [{label: "Medarbejder"}];
      let tablefooter = [{
        value: "Total",
        className: "fw-bold"
      }];
      var rowTotalCollection = {};
      rowTotalCollection["total"] = {
        turnover: 0,
        budget: 0,
        deviation: 0,
      };

      let colTotalCollection = {};

      if (a.length > 0) {
        let countCols = 1;
        for (var key in a[0].data) {
          

          
          // laver kolonner
          if (a[0].data.hasOwnProperty(key)) {
             //If countCols is dividable by 2
            const everySecond = countCols % 2 === 0;

            let dateElement = new Date(key);
            let dateString =
              dateElement.getMonth() + 1 + "-" + dateElement.getFullYear();
            tablesubheader.push({"label": "Oms.", className: everySecond ? "bg-light" : ""});
            tablesubheader.push({"label": "Budget", className: everySecond ? "bg-light" : ""});
            tablesubheader.push({"label": "Afv.", className: everySecond ? "bg-light" : ""});

            tableheader.push({ label: monthNames[dateElement.getMonth()] + " " + dateElement.getFullYear(), colspan: 3, className: everySecond ? "bg-light" : "" });
            colTotalCollection[dateString] = {
              turnover: 0,
              budget: 0,
              deviation: 0,
            };
          }
          //increment countCols
          countCols++;
        }
        const everySecondTotal = (countCols) % 2 === 0;
        tablesubheader.push({"label": "Oms.", className: everySecondTotal ? "bg-light" : ""});
        tablesubheader.push({"label": "Budget", className: everySecondTotal ? "bg-light" : ""});
        tablesubheader.push({"label": "Afv.", className: everySecondTotal ? "bg-light" : ""});
        
        tableheader.push({ label: "Total", colspan: 3, className: everySecondTotal ? "bg-light" : "" });
      }

      var tablebody = [];

      //Foreach element -- laver f
      a.forEach((element) => {
        // laver rækker
        let tablerow = [{
          value: element.associate,
          className: "ass_name"
        }];

        let rowtotal = {
          turnover: 0,
          budget: 0,
          deviation: 0,
        };
        let countCols = 1;
        for (var key in element.data) {
          // laver kolonner
          if (element.data.hasOwnProperty(key)) {
            //If countCols is dividable by 2
            const everySecond = countCols % 2 === 0;
            tablerow.push({
              value: element.data[key].turnover,
              className: everySecond ? "bg-light" : ""
            });
            tablerow.push({
              value: element.data[key].budget,
              className: everySecond ? "bg-light" : ""
            });
            tablerow.push({
              value: element.data[key].deviation,
              className: ((element.data[key].deviation < 0) ? "text-danger" : "text-success") + " " + (everySecond ? "bg-light" : "")
            });
            rowtotal = {
              turnover: rowtotal.turnover + element.data[key].turnover,
              budget: rowtotal.budget + element.data[key].budget,
              deviation: rowtotal.deviation + element.data[key].deviation,
            };
            let dateElement = new Date(key);
            let dateString =
              dateElement.getMonth() + 1 + "-" + dateElement.getFullYear();
            colTotalCollection[dateString] = {
              turnover:
                colTotalCollection[dateString].turnover +
                element.data[key].turnover,
              budget:
                colTotalCollection[dateString].budget +
                element.data[key].budget,
              deviation:
                colTotalCollection[dateString].deviation +
                element.data[key].deviation,
            };
            rowTotalCollection[element.data[key].associateId] = rowtotal;
          }
          //increment countCols
          countCols++;
        }
        const everySecondTotal = (countCols) % 2 === 0;
        tablerow.push({
          value: rowtotal.turnover,
          className: "fw-bold" + " " + (everySecondTotal ? "bg-light" : "")
        });
        tablerow.push({
          value: rowtotal.budget,
          className: "fw-bold" + " " + (everySecondTotal ? "bg-light" : "")
        });
        tablerow.push({
          value: rowtotal.deviation,
          className: ((rowtotal.deviation < 0) ? "text-danger fw-bold" : "text-success fw-bold") + " " + (everySecondTotal ? "bg-light" : "")
        });
        tablebody.push(tablerow);
      });
      let countCols = 1;
      //footer
      if (a.length > 0) {
        
        for (var key in a[0].data) {
          // laver kolonner
          if (a[0].data.hasOwnProperty(key)) {
            //If countCols is dividable by 2
            const everySecond = countCols % 2 === 0;
            let dateElement = new Date(key);
            let dateString =
              dateElement.getMonth() + 1 + "-" + dateElement.getFullYear();
            tablefooter.push({
              value: colTotalCollection[dateString].turnover,
              className: "fw-bold" + " " + (everySecond ? "bg-light" : "")
            });
            tablefooter.push({
              value: colTotalCollection[dateString].budget,
              className: "fw-bold" + " " + (everySecond ? "bg-light" : "")
            });
            tablefooter.push({
              value: colTotalCollection[dateString].deviation,
              className: ((colTotalCollection[dateString].deviation < 0) ? "text-danger fw-bold" : "text-success fw-bold") + " " + (everySecond ? "bg-light" : "")
            });

            rowTotalCollection["total"] = {
              turnover:
                rowTotalCollection["total"].turnover +
                colTotalCollection[dateString].turnover,
              budget:
                rowTotalCollection["total"].budget +
                colTotalCollection[dateString].budget,
              deviation:
                rowTotalCollection["total"].deviation +
                colTotalCollection[dateString].deviation,
            };
          }
          //increment countCols
          countCols++;
        }
      }
      const everySecondTotal = (countCols) % 2 === 0;
      tablefooter.push({
        value: rowTotalCollection["total"].turnover,
        className: "fw-bold" + " " + (everySecondTotal ? "bg-light" : "")
      });
      tablefooter.push({
        value: rowTotalCollection["total"].budget,
        className: "fw-bold" + " " + (everySecondTotal ? "bg-light" : "")
      });
      tablefooter.push({
        value: rowTotalCollection["total"].deviation,
        className: ((rowTotalCollection["total"].deviation < 0) ? "text-danger fw-bold" : "text-success fw-bold") + " " + (everySecondTotal ? "bg-light" : "")
      });

      setDataState({
        ...dataState,
        showData: true,
        apiRaw: response.data,
        tableHeader: tableheader,
        tableBody: tablebody,
        tableSubHeader: tablesubheader,
        tableFooter: tablefooter,
        rowTotal: rowTotalCollection,
        colTotal: colTotalCollection,
        error: null,
      });
      console.log(colTotalCollection);
    } else {
      setDataState({
        ...dataState,
        apiRaw: null,
        error: response.error,
      });
    }
  };
  getFilterList("associates", setAssociatesList, associatesList);
  useEffect(() => {
    getData();
  }, [filterState, associatesList]);

  return (
    <>
      <ApiAlerts dataState={dataState} setDataState={setDataState} />
      <MDBRow className="mt-5 pt-4 mb-2">
        <h2>Budgetoversigt</h2>
      </MDBRow>
      <MDBRow>
        <div className="card mb-5">
          <div className="card-body" style={{ textAlign: "center" }}>
            <h5
              className="card-title mb-0 text-center"
              onClick={toggleFilterCollapse}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              <MDBIcon fas icon="filter" /> Indstil filtre
            </h5>
            <MDBCollapse show={showFilterCollapse}>
              <MDBRow className="mt-4">
                <MDBCol className="col-md-12 col-lg-6">
                  {associatesList.showFilter && (
                    <MDBSelect
                      data={associatesList.list}
                      multiple
                      label="Medarbejdere"
                      selectAllLabel="Vælg alle"
                      clearBtn
                      search
                      searchLabel="Søg..."
                      onValueChange={(e) => {
                        console.log('test');
                        setRoute(filterState.route, {
                          ...filterState.params,
                          associate_id: e.map((item) => item.value),
                        });
                      }
                      }
                    />
                  )}
                </MDBCol>
                <MDBCol className="col-md-12 col-lg-6">
                  <DateRangePicker
                    onChange={(item) => {
                      setRoute(filterState.route, {
                        ...filterState.params,
                        startDate: formattedDate(item.selection.startDate),
                        endDate: formattedDate(item.selection.endDate),
                      });
                      setDatePickerState([item.selection]);
                      console.log(item);
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    locale={da}
                    ranges={datePickerState}
                    direction="vertical"
                    staticRanges={defaultStaticRanges}
                    inputRanges={[]}
                    weekStartsOn={1}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </div>
        </div>
      </MDBRow>
      <MDBRow>
        <MDBCol className="col-md-12 col-lg-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Omsætning og budget fordelt på medarbejdere
              </h5>
              <MDBChart
                type="bar"
                data={{
                  labels: associatesList.list
                    .filter((item) => {
                      if (dataState.rowTotal[item.value] !== undefined)
                        return true;
                    })
                    .map((item) => item.text),
                  datasets: [
                    // First dataset (bar)
                    {
                      label: "Omsætning",
                      data:
                        dataState.showData &&
                        dataState.rowTotal &&
                        associatesList.list
                          .filter((item) => {
                            if (dataState.rowTotal[item.value] !== undefined)
                              return true;
                          })
                          .map(
                            (item) => dataState.rowTotal[item.value].turnover
                          ),
                      order: 2,
                    },
                    // Second dataset (line)
                    {
                      label: "Budget",
                      data:
                        dataState.showData &&
                        dataState.rowTotal &&
                        associatesList.list
                          .filter((item) => {
                            if (dataState.rowTotal[item.value] !== undefined)
                              return true;
                          })
                          .map((item) => dataState.rowTotal[item.value].budget),
                      type: "line",
                      order: 1,
                      backgroundColor: "rgba(66, 133, 244, 0.0)",
                      borderColor: "#94DFD7",
                      borderWidth: 2,
                      pointBorderColor: "#94DFD7",
                      pointBackgroundColor: "#94DFD7",
                      lineTension: 0.0,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </MDBCol>
        <MDBCol className="col-md-12 col-lg-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Omsætning og budget fordelt på måneder</h5>
              <MDBChart
                type="bar"
                data={{
                  labels: dataState.showData && Object.keys(dataState.colTotal),
                  datasets: [
                    // First dataset (bar)
                    {
                      label: "Omsætning",
                      data:
                        dataState.showData &&
                        dataState.colTotal &&
                        Object.keys(dataState.colTotal).map(
                          (item) => dataState.colTotal[item].turnover
                        ),
                      order: 2,
                    },
                    // Second dataset (line)
                    {
                      label: "Budget",
                      data:
                        dataState.showData &&
                        dataState.colTotal &&
                        Object.keys(dataState.colTotal).map(
                          (item) => dataState.colTotal[item].budget
                        ),
                      type: "line",
                      order: 1,
                      backgroundColor: "rgba(66, 133, 244, 0.0)",
                      borderColor: "#94DFD7",
                      borderWidth: 2,
                      pointBorderColor: "#94DFD7",
                      pointBackgroundColor: "#94DFD7",
                      lineTension: 0.0,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-5 mt-5">
        {/* Display Table */}
        {dataState.showData && dataState.tableBody.length > 0 && (
          <>
            <MDBCol className="col-12" id="budgetTable">
              <TableComp
                _tableBody={dataState.tableBody}
                _tableHeader={dataState.tableHeader}
                _tableSubHeader={dataState.tableSubHeader}
                _tableFooter={dataState.tableFooter}
              />
            </MDBCol>
            
          </>
        )}
        {/* No Data */}
        {dataState.showData && dataState.tableBody.length == 0 && (
          <MDBCol className="col-12">
            <p className="text-center">Datsættet returnerede ingen data...</p>
          </MDBCol>
        )}
        {/* Loading */}
        {!dataState.showData && (
          <MDBCol className="col-12">
            <div className="d-flex justify-content-center">
              <MDBSpinner size="lg" role="status">
                <span className="visually-hidden">Indlæser...</span>
              </MDBSpinner>
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </>
  );
};

export default withAuthenticationRequired(Budgetoversigt, {
  onRedirecting: () => <Loading />,
});
