import React from "react"; 
//Import the same as above, but from mdb-react-ui-kit
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
 
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const { user } = useAuth0();

  return (
    <MDBContainer className="mb-5">
      <MDBRow className="align-items-center profile-header mb-5 text-center text-md-left">
        <MDBCol md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </MDBCol>
        <MDBCol md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        {JSON.stringify(user, null, 2)}
      </MDBRow>
    </MDBContainer>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
