import React, { useState, useRef, useEffect } from 'react';  
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/logo.svg";
  import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBRipple
  } from "mdb-react-ui-kit";
  import { Link } from "react-router-dom";
  
  export default function Sidenav({ basicOpen, setBasicOpen }) {
    const { 
      isAuthenticated,
  
      logout,
    } = useAuth0();  
    const [backdrop, setBackdrop] = useState(true);
    const [basicCollapse1, setBasicCollapse1] = useState(false);
    const [basicCollapse2, setBasicCollapse2] = useState(false);
    const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
    const innerWidth = useRef(0);
  /*
    const checkResize = () => {
      if (window.innerWidth === innerWidth.current) {
        return;
      }
  
      innerWidth.current = window.innerWidth;
  
      if (window.innerWidth < 1850) { 
        setBasicOpen(false);
        setBackdrop(true);
      } else { 
        setBasicOpen(true);
        setBackdrop(false);
      }
    };
  */
    const handleBasicCollapse1 = () => {
      if (basicCollapse2 && !basicCollapse1) setBasicCollapse2(false);
  
      setBasicCollapse1(!basicCollapse1);
    };
  
  
  /*
    useEffect(() => {
      //checkResize();
  
      window.addEventListener("resize", checkResize);
  
      return () => {
        window.removeEventListener("resize", checkResize);
      };
    }, [checkResize]);
  */
    return (
      <>
        <MDBSideNav
          isOpen={basicOpen}
          backdrop={backdrop}
          getOpenState={(e) => setBasicOpen(e)}
        >
          <MDBRipple tag="a" className="d-flex justify-content-center py-4">
            <img
              id="EasyFair-logo"
              src={logo}
              alt="EasyFair"
              draggable="false"
            />
          </MDBRipple>
          <MDBSideNavMenu>
            <MDBSideNavItem>
              <Link to="/" className="sidenav-link">
                <MDBIcon fas icon="home" className="fa-fw me-3" />
                Forside
              </Link>
            </MDBSideNavItem>
            {isAuthenticated && (
            <MDBSideNavItem>
              <Link to="/Sagsoversigt" className="sidenav-link">
                <MDBIcon fas icon="chart-pie" className="fa-fw me-3" />
                Sagsoversigt
              </Link>
            </MDBSideNavItem>
            )}
            {isAuthenticated && (
            <MDBSideNavItem>
              <Link to="/Pipelineoversigt" className="sidenav-link">
                <MDBIcon fas icon="chart-line" className="fa-fw me-3" />
                Pipelineoversigt
              </Link>
            </MDBSideNavItem>
            )}
            {isAuthenticated && (
            <MDBSideNavItem>
              <Link to="/Budgetoversigt" className="sidenav-link">
              <MDBIcon fas icon="money-bill" className="fa-fw me-3" />
                Budgetoversigt
              </Link>
            </MDBSideNavItem>
            )}
           {isAuthenticated && (
            <MDBSideNavItem>
              <MDBSideNavLink icon="angle-down" onClick={handleBasicCollapse1}>
                <MDBIcon fas icon="cogs" className="fa-fw me-3" />
                Profil
              </MDBSideNavLink>
              <MDBSideNavCollapse show={basicCollapse1}>
                <Link to="/profile" className="sidenav-link">
                <MDBIcon far icon="user-circle" className="fa-fw me-3"  />
                  Se profil
                </Link>
                <Link className="sidenav-link" to="" onClick={() => logoutWithRedirect()}>
                <MDBIcon fas icon="power-off" className="fa-fw me-3" />
                  Log ud
                </Link>
              </MDBSideNavCollapse>
            </MDBSideNavItem>
            )}
            
          </MDBSideNavMenu>
        </MDBSideNav>
      </>
    );
  }
  