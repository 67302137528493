import React from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon, 
  MDBBtn
} from "mdb-react-ui-kit";

export default function Topnav({updateSidenav, sidenavState }) {
 

  return (
    <MDBNavbar id="main-navbar" expand="lg" light fixed="top" bgColor="light">
      <MDBContainer fluid>
        <MDBBtn onClick={() => updateSidenav(!sidenavState)} className="shadow-0 p-0 me-3 d-block" color="light">
          <MDBIcon icon="bars" size="lg" fas /> Åben menu
        </MDBBtn> 
      </MDBContainer>
    </MDBNavbar>);
}
