import { startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, addMonths, addYears, startOfYear, endOfYear } from 'date-fns';

import { createStaticRanges } from 'react-date-range';

export function formattedDate(d = new Date) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${year}-${month}-${day}`;
}

const startOfLastMonth = startOfMonth(addMonths(new Date(), -1));
const endOfLastMonth = endOfMonth(addMonths(new Date(), -1));
const startOfNextMonth = startOfMonth(addMonths(new Date(), 1));
const endOfNextMonth = endOfMonth(addMonths(new Date(), 1));
const startOfNextQuarter = startOfQuarter(addMonths(new Date(), 3));
const endOfNextQuarter = endOfQuarter(addMonths(new Date(), 3));
const startOfLastQuarter = startOfQuarter(addMonths(new Date(), -3));
const endOfLastQuarter = endOfQuarter(addMonths(new Date(), -3));
const startOfNextYear = startOfYear(addYears(new Date(), 1));
const endOfNextYear = endOfYear(addYears(new Date(), 1));
const startOfLastYear = startOfYear(addYears(new Date(), -1));
const endOfLastYear = endOfYear(addYears(new Date(), -1));

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Sidste Måned',
    range: () => ({
      startDate: startOfLastMonth,
      endDate: endOfLastMonth,
    }),
  },
  {
    label: 'Denne Måned',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
  },
  {
    label: 'Næste Måned',
    range: () => ({
      startDate: startOfNextMonth,
      endDate: endOfNextMonth,
    }),
  },

  {
    label: 'Sidste Kvartal',
    range: () => ({
      startDate: startOfLastQuarter,
      endDate: endOfLastQuarter,
    }),
  },
  {
    label: 'Dette Kvartal',
    range: () => ({
      startDate: startOfQuarter(new Date()),
      endDate: endOfQuarter(new Date()),
    }),
  },
  {
    label: 'Næste Kvartal',
    range: () => ({
      startDate: startOfNextQuarter,
      endDate: endOfNextQuarter,
    }),
  },
  {
    label: 'Sidste År',
    range: () => ({
      startDate: startOfLastYear,
      endDate: endOfLastYear,
    }),
  },
  {
    label: 'Dette År',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
  },
  {
    label: 'Næste År',
    range: () => ({
      startDate: startOfNextYear,
      endDate: endOfNextYear,
    }),
  },
]);