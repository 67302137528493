import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center mt-5">
    <div className="logo" />
    <p>
      Rapporter er udviklet og vedligeholdes af <a href="https://doweb.dk">DoWEB ApS</a>
    </p>
  </footer>
);

export default Footer;
