
import { getConfig } from "../config";
const { apiOrigin = "https://easyfair.doweb.dk/backend", audience } = getConfig();
const stateTemplate = {
  data: [],
  timestamp: null,
  error: null
};


const handleConsent = async (state, setState, getAccessTokenWithPopup, getAccessTokenSilently, route = 'ping', params = {}, method = 'GET') => {
  try {
    await getAccessTokenWithPopup();
    setState({
      ...state,
      error: null,
    });
  } catch (error) {
    setState({
      ...state,
      error: error.error,
    });
  }
  await callApi(state, setState, getAccessTokenSilently, route, params, method);
};

const handleLoginAgain = async (state, setState, loginWithPopup, getAccessTokenSilently, route = 'ping', params = {}, method = 'GET') => {
  try {
    await loginWithPopup();
    setState({
      ...state,
      error: null,
    });
  } catch (error) {
    setState({
      ...state,
      error: error.error,
    });
  }

  await callApi(state, setState, getAccessTokenSilently, route, params, method);
};

const callApi = async (getAccessTokenSilently, route = 'ping', params = {}, method = 'GET') => {
  try {
    for (var param in params) {
      
      if (params.hasOwnProperty(param)) {
        
        if (typeof (params[param]) === Array) {
          if(params[param].length > 0){
            params[param] = param.join(',');
          }
          else{
            delete params[param];
          }
        }
        if(params[param] === null || params[param] === '' || params[param] === 0 ){
          delete params[param];
        }
      }
    };
    
    const token = await getAccessTokenSilently();
    let urlparams;

    if (method === 'GET') urlparams = '?' + new URLSearchParams(params);
    else urlparams = '';

    console.log(`${apiOrigin}/${route}${urlparams}`);

    const response = await fetch(`${apiOrigin}/${route}${urlparams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return { status: true, data: responseData };
  } catch (error) {
    return { status: false, error: error.error };
  }
};

export { handleConsent, handleLoginAgain, callApi, audience };