// Reactstrap
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import ReactHtmlParser from 'html-react-parser'; 

const TableComp = ({ _tableHeader, _tableSubHeader, _tableBody, _tableFooter }) => {
  if (_tableBody[0] !== undefined && typeof _tableBody[0] === 'object') {
    _tableBody.forEach((row, row_i) => {
      _tableBody[row_i] = Object.values(row);
    });
  }
  let viewTotal = [];

  const checkTotal = (footer, total) => {
    if(footer.length === 0){
      return total;
    }
    else{
      return footer;
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <MDBTable responsive borderless striped small>
            <MDBTableHead className="table-head">
              <tr className="table-head-items text-center">
                {
                  _tableHeader.map((item, i) => {
                    viewTotal[i] = {
                      value: null,
                      className: "fw-bold text-end"
                    };
                    return (<th className={item.hasOwnProperty('className') ? item.className : "text-start"} colSpan={item.colspan} key={i}>{item.label}</th>);
                  })
                }
              </tr>
              {_tableSubHeader.length > 0 && (
                <tr className="table-subhead-items">
                  {
                    _tableSubHeader.map((item, i) => {
                      viewTotal[i] = {
                        value: null,
                        className: "fw-bold"
                      };
                      return (<th className={item.hasOwnProperty('className') ? item.className : "text-start"} key={i}>{item.label}</th>);
                    })
                  }
                </tr>
              )}
           </MDBTableHead>
            <MDBTableBody className="table-body fs-7">
              {_tableBody.map((row, row_i) => (
                <tr key={row_i}>
                  {row.map((col, col_i) => (
                    <td className={col.hasOwnProperty('className') ? col.className : ""} key={col_i}>
                      {col.hasOwnProperty('value') && typeof col.value === "number" ? 
                        (viewTotal[col_i].value = viewTotal[col_i].value + col.value) &&
                        col.value.toLocaleString('da-DK', { minimumFractionDigits: 2 }).replace(',00', '') 
                      : 
                        (!isNaN(Date.parse(col.value))) ?
                          col.value.toLocaleString()
                        :
                          typeof col.value === "string" ? ReactHtmlParser(col.value) : "Invalid Value"
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </MDBTableBody>
        
            <tfoot className="table-footer">
              <tr>
                {
                  
                  checkTotal(_tableFooter, viewTotal).map((col, col_i) => {
                    return (<td className={
                      (col.className) ? col.className : ""
                    } key={col_i}>{
                        typeof col.value === "number" ?
                        col.value.toLocaleString('da-DK', { minimumFractionDigits: 2 }).replace(',00', '') 
                               
                          :
                          (!isNaN(Date.parse(col.value))) ?
                            col.value.toLocaleString()
                            :
                            col.value
                      }</td>);
                  })
                }
              </tr>
            </tfoot>
         </MDBTable>
        </div>
      </div>
    </>
  )
}

export { TableComp };