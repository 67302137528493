import React from "react";
import {handleConsent, handleLoginAgain, audience} from "../../utils/Api"; 
//import the same as above but from mdb-react-ui-kit
import { MDBAlert } from "mdb-react-ui-kit";
import { useAuth0 } from "@auth0/auth0-react"; 



const ApiAlerts = (state, setState) => {
  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };
  const {  
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();
  return ( 
    <>
  {state.error === "consent_required" && (
    <MDBAlert color="warning">
      You need to{" "}
      <a
        href="#/"
        class="alert-link"
        onClick={(e) => handle(e, handleConsent(state, setState, getAccessTokenWithPopup))}
      >
        consent to get access to users api
      </a>
    </MDBAlert>
  )}

  {state.error === "login_required" && (
    <MDBAlert color="warning">
      You need to{" "}
      <a
        href="#/"
        class="alert-link"
        onClick={(e) => handle(e, handleLoginAgain(state, setState,loginWithPopup))}
      >
        log in again
      </a>
    </MDBAlert>
  )} 

  {!audience && (
    <MDBAlert color="warning">
      <p>
        You can't call the API at the moment because your application does
        not have any configuration for <code>audience</code>, or it is
        using the default value of <code>YOUR_API_IDENTIFIER</code>. You
        might get this default value if you used the "Download Sample"
        feature of{" "}
        <a href="https://auth0.com/docs/quickstart/spa/react">
          the quickstart guide
        </a>
        , but have not set an API up in your Auth0 Tenant. You can find
        out more information on{" "}
        <a href="https://auth0.com/docs/api">setting up APIs</a> in the
        Auth0 Docs.
      </p>
      <p>
        The audience is the identifier of the API that you want to call
        (see{" "}
        <a href="https://auth0.com/docs/get-started/dashboard/tenant-settings#api-authorization-settings">
          API Authorization Settings
        </a>{" "}
        for more info).
      </p>

      <p>
        In this sample, you can configure the audience in a couple of
        ways:
      </p>
      <ul>
        <li>
          in the <code>src/index.js</code> file
        </li>
        <li>
          by specifying it in the <code>auth_config.json</code> file (see
          the <code>auth_config.json.example</code> file for an example of
          where it should go)
        </li>
      </ul>
      <p>
        Once you have configured the value for <code>audience</code>,
        please restart the app and try to use the "Ping API" button below.
      </p>
    </MDBAlert>
  )} 
  </>
  )};
export default ApiAlerts;
