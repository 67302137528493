import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MDBBtn } from "mdb-react-ui-kit";
import logo from "../assets/logo.svg";

const Hero = () => {
  const { 
    isAuthenticated,
    loginWithRedirect
  } = useAuth0(); 
  return(
  <>
  <div className="text-center hero my-5">
  
    <h1 className="mb-4">Easy Fair Salgsrapporter</h1>

    <p className="lead">
      Denne applikation viser data om sager og projekter fra EasyFair's systemer.
      
    </p>
    {!isAuthenticated && ( 
      <div className="d-grid gap-2 col-6 mx-auto">
              <MDBBtn
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </MDBBtn> 
                  </div>
            )}
  </div>
  </>
);
};

export default Hero;
