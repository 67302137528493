// React
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Bootstrap - React
import { MDBContainer } from "mdb-react-ui-kit";

// Components
import Loading from "./components/Loading";
import Topnav from "./components/Topnav";
import Footer from "./components/Footer";

// Views
import Home from "./views/Home";
import Profile from "./views/Profile";
import Sagsoversigt from "./views/Sagsoversigt";
import Pipeline from "./views/Pipeline";
import Budget from "./views/Budget";
// Hooks
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Sidenav from "./components/Sidenav";
initFontAwesome();

const App = () => {
  const [basicOpen, setBasicOpen] = useState(false);
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter history={history}>
      <header>
        <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen} />
        {!basicOpen && (
          <Topnav updateSidenav={setBasicOpen} sidenavState={basicOpen} />
        )}
      </header>
      <main>
        <MDBContainer fluid className="flex-grow-1 mt-5">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Sagsoversigt" element={<Sagsoversigt />} />

            <Route path="/Pipelineoversigt" element={<Pipeline />} />

            <Route path="/Budgetoversigt" element={<Budget />} />
          </Routes>
        </MDBContainer>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
